@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #f5f5f5;
}
.flex-container {
  display:flex;
  flex-direction:column;

  /* fallback height */
  min-height:100vh;

  /* new small viewport height for modern browsers */
  min-height:100svh;
}
.flex-container > footer {
  margin-top:auto;
}
.ant-btn-primary{
  background-color: #f97316;
}
.ant-menu {
  background: #3b5998;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #ffffff;
}
