table {
  font-family: "Poppins", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

/* table td,
table th {
  border: 0px solid #e9ebec;
  padding: 8px;
} */
tbody,
td,
tfoot,
th,
thead,
tr {
  border-bottom: 1px solid;
  border-color: #e9ebec;
  padding: 0.75rem 0.6rem;
}

/* table tr:nth-child(even) {
  background-color: #eef2ff;
}*/

table tr:hover {
  background-color: #f3f6f9;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #f3f6f9;
}
